import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { RelationTable } from '../../class/RelationTable';
import {Data} from '../../class/Data';
import { SharedService } from '../../services/shared';
import { Observable } from 'rxjs/Observable';
import { TableInfo } from 'app/class/TableInfo';
import { Router } from '@angular/router'
import { Connect } from '../../services/connect';



@Component({
  selector: 'app-relation-table',
  templateUrl: './relation-table.component.html',
  styleUrls: ['./relation-table.component.css']
})
export class RelationTableComponent implements OnInit {

  @Input('data') data: Data = new Data(new TableInfo([]));
    @Input() relation_table_hash: any
    // public relation_table_info_by_table: Object = {};
    @Input() is_relation_view_mode:  boolean = false;
    public relation_table: RelationTable = null;
    public relation_table_info: TableInfo = null;

  public count: number;
  public per_page: number = 10;
  public page: number = 1;
  private numbers: Array<number>;
  public loading: boolean = false;

  public delete_data: Array<any>;
  @ViewChild('deleteRelatedItemModal') deleteRelationModal: any;


  constructor(
    private _connect: Connect,
    public _share: SharedService,
    private _router: Router
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
      console.log(this.relation_table_hash)
      if(!this.relation_table_hash) return;
      this.count = this.relation_table_hash.total_count;
      let total_page = Math.ceil(this.count / this.per_page);
      this.numbers = Array(total_page).fill(0).map((x, i) => i + 1);

      this._share.getTableInfo(this.relation_table_hash.table).subscribe(_table_info => {
          this.relation_table_info = _table_info;
          this.relation_table = new RelationTable(this.relation_table_hash, _table_info)
      });
  }

    public getRelationViewFields(): Observable<Array<string>> {
    return new Observable(observer => {
        this._share.getTableInfo(this.relation_table.table).subscribe(_table_info => {
        if (!_table_info) {
          observer.next([]);
        } else {
            observer.next(this.relation_table.getViewFields(_table_info))
        }
      })
    });
  }

  async onPageChange(page) {
    this.page = page;
    this.loading = true;
    await this._connect.getList(this.relation_table_info, page, this.per_page).subscribe((data) => {
      this.relation_table = new RelationTable({ ...this.relation_table_hash, data_a: data.data_a }, this.relation_table_info);
      this.loading = false;
    });
  }

  goToTableEdit(table_info: TableInfo): void {
    this._router.navigate([this._share.getAdminTable(), table_info.admin_table_setting.table, 'edit', 'new']);
  }

  openAdminTableDeleteModal = (data: Data) => {
    if (data.raw_data['id'] === null) {
      console.error('primary-keyがNULLです', 'エラー');
      return;
    }
    this.delete_data = [data.raw_data['id']];
    this.deleteRelationModal.show();
  }
  delete(id_a: any): void {
    this.deleteRelationModal.show();
    this._connect.post('/admin/delete/' + this.relation_table.table, { 'id_a': id_a }).subscribe(
      (jsonData) => {
        console.log('related record delete', jsonData);
        let id = id_a[0];
        for (let i = 0; i < this.relation_table.data_a.length; i++) {
          if (this.relation_table.data_a[i].raw_data['id'] === id) {
            this.relation_table.data_a.splice(i, 1);
            break;
          }
        }
        this.deleteRelationModal.hide();
      }, (error) => {
        this.deleteRelationModal.hide();
      }
    );
  }

}
